import React from "react"

import joriba from "../images/joriba.svg"

import styles from "./footer.module.css"

export default function Footer() {
  return (
    <footer className={styles.footer}>
      <h2 className={styles.title}>
        <p>Atelier Eclair is a brand by Joriba</p>
      </h2>

      <a
        className={styles.logo}
        href="https://www.joriba.be"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={joriba} alt="Joriba" />
      </a>

      <div>
        <p>
          ’t Lindeke 20 <br />
          8880 Sint-Eloois-Winkel <br />
          <a href="tel:003256500750">+32 56 50 07 50</a>
          <br />
          <a href="mailto:info@joriba.be">info@joriba.be</a>
        </p>
      </div>
    </footer>
  )
}
